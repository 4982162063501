import React from 'react';
import { fromJS } from 'immutable';
import { useHistory } from 'react-router';
import { Trans } from 'react-i18next';

import { Button } from '@ets-global/b2c-website-ui';

import { paths } from '../../paths';
import { useQuery, useRouter } from '../../hooks';
import { SmallTitle } from '../../components/Base/Title';
import { FAQ_CATEGORIES } from '../../graphql/queries';
import HelpCenterSidebar from './Sidebar';
import FAQBlock from './FAQBlock';
import FAQBlockSkeleton from './FAQBlockSkeleton';
import HelpCenterSidebarSkeleton from './SidebarSkeleton';
import NotFoundHandler from '../../components/NotFoundHandler';

import './styles.scss';

const HelpCenter = () => {
  const history = useHistory();
  const {
    countryEmoIso2,
    lang,
    currentRoute: { params },
  } = useRouter();

  let question = null;

  const {
    data: { faqCategories: categories },
    loading,
  } = useQuery(FAQ_CATEGORIES, { variables: { lang, country: countryEmoIso2 } });

  let content = (
    <main className="help-center__main">
      <article className="help-center__main-inner">
        <HelpCenterSidebarSkeleton />
        <FAQBlockSkeleton />
      </article>
    </main>
  );

  if (!loading) {
    const faqCategories = fromJS(categories);

    if (!faqCategories || faqCategories.size === 0) return <NotFoundHandler />;

    let category = faqCategories.first();
    if (params.slug) {
      const currentCategory = faqCategories.find((category) => category.get('slug') === params.slug);
      if (!currentCategory || currentCategory.size <= 0) return <NotFoundHandler />;
      category = currentCategory;

      if (params.question) {
        const currentQuestion = category.get('faqs').find((question) => question.get('slug') === params.question);
        if (!currentQuestion || currentQuestion.size <= 0) return <NotFoundHandler />;
        question = currentQuestion;
      }
    }

    content = (
      <main className="help-center__main">
        <article className="help-center__main-inner">
          <HelpCenterSidebar categories={faqCategories} />
          <FAQBlock category={category} selectedQuestion={question} locale={lang} history={history} />
        </article>
      </main>
    );
  }

  return (
    <main className={'main'}>
      <div className="help-center">
        <section className="help-center__header">
          <div className="container container--padding">
            <div className="help-center__header-inner">
              <div className="help-center__header-column">
                <SmallTitle component={'p'}>
                  <Trans>help-center.title</Trans>
                </SmallTitle>
              </div>
              <div className="help-center__header-column help-center__header-column--last">
                <Button to={paths.LANDING_CONTACT} className="button--transparent-light">
                  <Trans>common.contact_us</Trans>
                </Button>
              </div>
            </div>
          </div>
        </section>
        {content}
      </div>
    </main>
  );
};

export default HelpCenter;
