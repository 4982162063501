import { gql } from '@apollo/client';

import { Image, Link, Meta } from '../fragments';

export const PROGRAMME = gql`
  query PROGRAMME($lang: String!, $slug: String!, $country: String!) {
    programme(lang: $lang, slug: $slug, country: $country) {
      publicationDate
      title
      miniTitle
      marketingHook
      baseline
      miniDescription
      shortDescription(country: $country, lang: $lang)
      description(country: $country, lang: $lang)
      images {
        ...Image
      }
      links {
        ...Link
      }
      testimonial {
        title
        subtitle
      }
      testTypeFamilies {
        id
        slug
        name
        miniDescription
        color
      }
      profiles {
        slug
        title
        marketingHook
        description: shortDescription(country: $country, lang: $lang)
        images {
          ...Image
        }
        scenarios {
          editorialContentHighlighted {
            ... on Testimonial {
              quote: title
              author: subtitle
              images {
                ...Image
              }
              smallImage {
                ...Image
              }
            }
          }
        }
      }
      color
      meta {
        ...Meta
      }
    }
  }
  ${Image}
  ${Link}
  ${Meta}
`;
