import React, { Fragment } from 'react';

import ScenarioItem from './scenarioItem';

const ScenarioList = ({ scenarios }) => (
  <Fragment>
    {scenarios.map((scenario, key) => (
      <ScenarioItem key={key} scenario={scenario} />
    ))}
  </Fragment>
);

export default ScenarioList;
