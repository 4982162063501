import React from 'react';
import { fromJS } from 'immutable';
import { Trans } from 'react-i18next';
import uniq from 'lodash/uniq';

import { Loader } from '@ets-global/b2c-website-ui';

import { useQuery, useRouter } from '../../hooks';
import { PrismicRichText } from '../../components/Base';
import Separator from '../../components/Base/Separator/Separator';
import Banner from '../../components/Base/Banner';
import { PROFILE } from '../../graphql/queries';
import ScenarioList from './ScenarioList';
import LastEditorialContent from '../../containers/LastEditorialContent';
import HeaderMeta from '../../components/HeaderMeta';
import NotFoundHandler from '../../components/NotFoundHandler';
import { LocalizedRedirect } from '../../components/Routing';

import './styles.scss';

const ProfilePage = () => {
  const {
    lang,
    countryEmoIso2,
    currentRoute: { params },
    generatePrismicDocumentTypeOrCustomLinkPath,
  } = useRouter();

  let {
    data: { profile },
    loading,
  } = useQuery(PROFILE, { variables: { lang, country: countryEmoIso2, slug: params.slug } });

  if (loading) {
    return (
      <main className={'main main--no-offset-top'}>
        <Loader />
      </main>
    );
  }
  if (!profile) {
    return <NotFoundHandler />;
  }
  if (profile.redirectLink?.slug) {
    return <LocalizedRedirect status={301} to={generatePrismicDocumentTypeOrCustomLinkPath(profile.redirectLink)} />;
  }

  profile = fromJS(profile);

  let testTypeFamilyIds = profile.get('scenarios').reduce((ids, scenario) => {
    const currentIds = scenario
      .get('testTypeFamilies')
      .map((testTypeFamily) => testTypeFamily.get('id'))
      .toJS();
    ids = [...ids, ...currentIds];

    return ids;
  }, []);
  testTypeFamilyIds = uniq(testTypeFamilyIds);

  return (
    <main className={'main main--no-offset-top'}>
      <HeaderMeta page={'profile'} meta={profile.get('meta').toJS()} />
      <Banner
        title={profile.get('title')}
        subtitle={profile.get('marketingHook')}
        baseline={profile.get('baseline')}
        images={profile.get('images').toJS()}
        type={'profile'}
      />
      <article className="profile__main">
        {profile.get('description') && (
          <section className="container container--padding">
            <PrismicRichText className="introduction" richText={profile.get('description').toJS()} />
          </section>
        )}
        <Separator />
        <ScenarioList scenarios={profile.get('scenarios')} />
        <aside className="container container--padding">
          <div className="profile__more">
            <h3 className="profile__more-title">
              <Trans>profile.more</Trans>
            </h3>
            <LastEditorialContent className={'profile__more-news'} testTypeFamilyIds={testTypeFamilyIds} />
          </div>
        </aside>
      </article>
    </main>
  );
};

export default ProfilePage;
