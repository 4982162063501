import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { Button } from '@ets-global/b2c-website-ui';

import { paths } from '../../paths';
import { BorderedBoxesList, BorderedBox } from '../BorderedBoxesList';

const TestTypeFamilies = ({ families, color }) => {
  const borderedBoxes = families.map((family, key) => (
    <BorderedBox key={key} small title={family.name} color={color}>
      <p>{family.miniDescription}</p>
      {family.slug && (
        <Button className="bordered-box__button" to={paths.TEST_TYPE_FAMILY} params={{ slug: family.slug }}>
          <Trans>test-type-family.read-more</Trans>
        </Button>
      )}
    </BorderedBox>
  ));

  return <BorderedBoxesList borderedBoxes={borderedBoxes} />;
};

TestTypeFamilies.propType = {
  color: PropTypes.string,
  families: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      miniDescription: PropTypes.string.isRequired,
    }),
  ),
};

export default TestTypeFamilies;
