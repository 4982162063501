import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Trans } from 'react-i18next';
import { Link, SelectField, FieldWrapper } from '@ets-global/b2c-website-ui';
import { useHistory } from 'react-router';

import { useRouter } from '../../hooks';
import { paths } from '../../paths';
import InjectHtml from '../../components/InjectHtml';
import MarketingBannerContainer, { marketingBannerPages } from '../../containers/MarketingBanner';

const HelpCenterSidebar = ({ categories }) => {
  const history = useHistory();
  const {
    generatePath,
    currentRoute: { params },
  } = useRouter();

  return (
    <section className="help-center__main-column help-center__main-column--second">
      <div className="container container--padding">
        <FieldWrapper
          id={'categories'}
          className={'field-wrapper--select'}
          label={<Trans>help-center.categories.title</Trans>}
          hasValue={true}
        >
          <SelectField
            name={'categories'}
            value={params.slug}
            options={categories
              .map((category) => ({ value: category.get('slug'), label: category.get('name') }))
              .toArray()}
            onChange={(e) => history.push(generatePath(paths.HELP_CENTER, { slug: e.target.value }))}
          />
        </FieldWrapper>
        <ul className="help-center__categories">
          {categories.map((category, index) => {
            const isActive = params.slug === category.get('slug') || (!params.slug && index === 0);

            return (
              <li
                key={index}
                className={classNames('help-center__category', {
                  'help-center__category--active': isActive,
                })}
              >
                <Link
                  to={generatePath(paths.HELP_CENTER, { slug: category.get('slug'), question: null })}
                  className="help-center__category-button"
                >
                  <span className="help-center__category-icon">
                    <img src={category.getIn(['picto', 'url'])} alt={category.get('name')} className={'icon'} />
                  </span>
                  <InjectHtml
                    component={isActive ? 'h1' : 'span'}
                    className="help-center__category-name"
                    html={category.get('name')}
                  />
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <MarketingBannerContainer page={marketingBannerPages.faq} small={true} />
    </section>
  );
};

HelpCenterSidebar.propTypes = {
  categories: PropTypes.instanceOf(List).isRequired,
};

export default HelpCenterSidebar;
