import React from 'react';
import classNames from 'classnames';

import SkeletonItem from '../../components/SkeletonItem';

const FAQBlockSkeleton = () => (
  <div className="help-center__main-column help-center__main-column--last">
    <div className="help-center__tabs">
      {[...Array(2)].map((tab, key) => (
        <div key={key} className={classNames('help-center__tab', { 'help-center__tab--active': key === 0 })}>
          <div className={'help-center__tab-button'}>
            <SkeletonItem style={{ height: '27px', width: '50%' }} />
          </div>
        </div>
      ))}
    </div>
    <div className="container container--padding">
      <SkeletonItem style={{ height: '70px', width: '100%' }} className={'field-wrapper--select'} />
      <div className="help-center__tab-content">
        <SkeletonItem style={{ height: '40px', width: '50%' }} className={'help-center__category-title'} />
        <div className="advice__question">
          <div className="advice__question-toggler">
            <div className="advice__question-button">
              <SkeletonItem style={{ height: '35px', width: '80%' }} />
            </div>
          </div>
          <div className="advice__question-toggler">
            <div className="advice__question-button">
              <SkeletonItem style={{ height: '35px', width: '80%' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FAQBlockSkeleton;
