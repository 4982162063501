import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';

import { Link, Button } from '@ets-global/b2c-website-ui';
import { useRouter } from '../../../hooks';

import { paths } from '../../../paths';
import { setParamsSearch } from '../../../helpers/router';

import Section from '../../../components/Section';
import TestBlock from '../../../components/TestBlock';
import Separator from '../../../components/Base/Separator/Separator';

const ScenarioItem = ({ scenario }) => {
  const { generatePath } = useRouter();

  return (
    <Fragment>
      <section className="container container--padding profile__section">
        <Section
          title={scenario.get('title')}
          subtitle={scenario.get('subtitle')}
          description={scenario.get('description')}
          buttons={
            scenario.get('testTypeFamilies').size > 0
              ? [
                  {
                    label: <Trans>profile.test-block.prep-tools-link</Trans>,
                    url: generatePath(paths.PREP_TOOLS),
                  },
                ]
              : []
          }
          links={scenario.get('links')}
          editorialContentHighlighted={scenario.get('editorialContentHighlighted')}
        />

        {scenario.get('testTypeFamilies').size > 0 && (
          <div className="profile__bloc-tests">
            {scenario.get('testTypeFamilies').map((testTypeFamily, key) => (
              <TestBlock
                key={key}
                title={testTypeFamily.get('name')}
                description={testTypeFamily.get('shortDescription')}
                richText={testTypeFamily.get('informationBoxContent')}
              >
                {testTypeFamily.get('slug') && (
                  <Link
                    icon="arrow"
                    to={paths.TEST_TYPE_FAMILY}
                    params={{
                      slug: testTypeFamily.get('slug'),
                    }}
                  >
                    <Trans>profile.test-block.product-link</Trans>
                  </Link>
                )}
                <Button
                  to={paths.SESSION_SEARCH}
                  search={setParamsSearch({
                    testTypeIds: testTypeFamily
                      .get('testTypes')
                      .map((testType) => testType.get('id'))
                      .toJS(),
                    showMap: false,
                  })}
                >
                  <Trans>profile.test-block.next-sessions-button</Trans>
                </Button>
              </TestBlock>
            ))}
          </div>
        )}
      </section>
      <Separator />
    </Fragment>
  );
};

export default ScenarioItem;
