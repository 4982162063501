import React from 'react';
import { Trans } from 'react-i18next';

import { Link, Loader } from '@ets-global/b2c-website-ui';

import { useRouter, useQuery } from '../../hooks';
import { PROGRAMME } from '../../graphql/queries/programme';
import { PrismicRichText } from '../../components/Base';
import TestTypeFamilies from '../../components/TestTypeFamilies';
import QuoteBanner from '../../components/QuoteBanner';
import ProfileBlock from '../../components/ProfileBlock';
import Banner from '../../components/Base/Banner';
import { PROGRAMME_COLOR } from '../../constants';
import HeaderMeta from '../../components/HeaderMeta';
import NotFoundHandler from '../../components/NotFoundHandler';

import './styles.scss';

const Programme = () => {
  const {
    lang,
    countryEmoIso2,
    currentRoute: { params },
  } = useRouter();
  const {
    data: { programme },
    loading,
  } = useQuery(PROGRAMME, { variables: { lang, slug: params.slug, country: countryEmoIso2 } });

  if (loading) {
    return (
      <main className="main main--no-offset-top">
        <Loader />
      </main>
    );
  } else if (!programme) {
    return (
      <main className="main main--no-offset-top">
        <NotFoundHandler />
      </main>
    );
  }

  const programmeColor = programme.color ? PROGRAMME_COLOR[programme.color] : null;

  return (
    <main className="main main--no-offset-top">
      <Banner
        title={programme.title}
        subtitle={programme.marketingHook}
        baseline={programme.baseline}
        images={programme.images}
        type={'programme-page'}
        borderColor={programmeColor}
      />
      <HeaderMeta page={'programme'} meta={programme.meta} />
      <article className="programme-page__main">
        <section className="container container--padding">
          <div className="programme-page__row">
            <div className="programme-page__column programme-page__column--first">
              <div className="programme-page__introduction">
                <PrismicRichText richText={programme.description} />
              </div>
            </div>
            <div className="programme-page__column programme-page__column--last programme-page__introduction-links">
              {programme.links.map(({ label, ...link }, key) => (
                <Link key={key} icon={'arrow'} className={'programme-page__link'} {...link}>
                  {label}
                </Link>
              ))}
            </div>
          </div>
          {programme.testTypeFamilies && (
            <TestTypeFamilies color={programmeColor} families={programme.testTypeFamilies} />
          )}
        </section>
        {programme.testimonial && (
          <QuoteBanner
            type={<Trans>quote-banner.testimony</Trans>}
            quote={programme.testimonial.title}
            author={programme.testimonial.subtitle}
            images={programme.images}
            borderColor={programmeColor}
            withBorder={true}
            className={'programme-page__quote-banner'}
          />
        )}
        {programme.profiles.map((profile, key) => {
          let profileBlockProps = {
            ...profile,
            testimonials: profile.scenarios
              .filter(
                ({ editorialContentHighlighted: editorialContent }) =>
                  editorialContent && editorialContent.__typename === 'Testimonial',
              )
              .map(({ editorialContentHighlighted: editorialContent }) => editorialContent),
          };

          return <ProfileBlock key={key} {...profileBlockProps} />;
        })}
      </article>
    </main>
  );
};

export default Programme;
