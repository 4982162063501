import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import classNames from 'classnames';

import { Link, Button } from '@ets-global/b2c-website-ui';

import Title from '../Base/Title/Title';

import { TestimonialBlock, CaseStudyBlock } from '../EditorialContent';

import { PrismicRichText } from '../Base';
import InjectHtml from '../InjectHtml';

import './styles.scss';

const Section = ({ title, subtitle, description, buttons, links, editorialContentHighlighted }) => {
  let editorialContentHighlightedRender = null;
  if (editorialContentHighlighted && editorialContentHighlighted.size > 0) {
    const mobile = editorialContentHighlighted
      .get('images')
      .find((image) => image.get('source') === 'MOBILE')
      .toJS();
    const image = editorialContentHighlighted.get('smallImage')
      ? editorialContentHighlighted.get('smallImage').toJS()
      : mobile;

    editorialContentHighlightedRender = (
      <Fragment>
        {editorialContentHighlighted.get('__typename') === 'Testimonial' && (
          <TestimonialBlock
            title={editorialContentHighlighted.get('quote')}
            author={editorialContentHighlighted.get('author')}
            image={image}
            className="section__testimony"
            backgroundColor={'blue'}
          />
        )}
        {editorialContentHighlighted.get('__typename') === 'CaseStudy' && (
          <CaseStudyBlock
            className="section__case-study"
            slug={editorialContentHighlighted.get('slug')}
            title={editorialContentHighlighted.get('title')}
            image={image}
          />
        )}
      </Fragment>
    );
  }

  return (
    <div className="section">
      <Title className="section__title">
        <InjectHtml html={title} />
      </Title>
      <div className="section__row">
        <div className="section__column">
          <InjectHtml component="h3" className="section__subtitle" html={subtitle} />
          {description && description.size > 0 && (
            <PrismicRichText className="section__content" richText={description.toJS()} />
          )}
        </div>
        <div className="section__column">
          {buttons &&
            buttons.map((button, index) => (
              <Button key={index} to={button.url} className={classNames('section__button', button.className)}>
                {button.label}
              </Button>
            ))}
          {links && (
            <ul className="section__list">
              {links
                .filter((link) => !!link.get('label'))
                .map((link, index) => (
                  <li key={index} className="section__list-item">
                    <Link {...link.toJS()} icon="arrow" className="section__list-item-link">
                      {link.get('label')}
                    </Link>
                  </li>
                ))}
            </ul>
          )}
          {editorialContentHighlightedRender}
        </div>
      </div>
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.instanceOf(List),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.any,
      className: PropTypes.string,
    }),
  ),
  links: PropTypes.instanceOf(List),
  editorialContentHighlighted: PropTypes.instanceOf(Map),
};

export default Section;
