import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Map } from 'immutable';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { SelectField, FieldWrapper } from '@ets-global/b2c-website-ui';

import Advice from '../../components/Advice';
import JsonLD from '../../components/JsonLD';
import { PrismicRichText } from '../../components/Base';
import HeaderMeta from '../../components/HeaderMeta';
import { paths } from '../../paths';
import { useRouter } from '../../hooks';

const FAQBlock = ({ category, selectedQuestion = null }) => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(
    selectedQuestion && selectedQuestion.get('targetAudience') === 'PROFESSIONAL' ? 'professional' : 'individual',
  );
  const { t } = useTranslation();
  const { generatePath } = useRouter();

  const onTabChange = (tabName) => {
    setCurrentTab(tabName);
    history.push(generatePath(paths.HELP_CENTER, { slug: category.get('slug'), question: null }));
  };

  const onQuestionChange = (question) => {
    history.push(
      !selectedQuestion || question.get('slug') !== selectedQuestion.get('slug')
        ? generatePath(paths.HELP_CENTER, { slug: category.get('slug'), question: question.get('slug') })
        : generatePath(paths.HELP_CENTER, { slug: category.get('slug'), question: null }),
    );
  };

  const tabs = [
    { value: 'individual', label: t('help-center.identity.individual.title') },
    { value: 'professional', label: t('help-center.identity.professional.title') },
  ];

  const questions = category
    .get('faqs')
    .filter((faq) => faq.get('targetAudience') === 'ALL' || faq.get('targetAudience') === currentTab.toUpperCase());

  const jsonLDQuestions = questions.toJSON().map((question) => ({
    '@type': 'Question',
    name: question['question'],
    acceptedAnswer: {
      '@type': 'Answer',
      text: ReactDOMServer.renderToStaticMarkup(<PrismicRichText richText={question['answer']} urlAbsolute={true} />),
    },
  }));

  let meta = category.get('meta').toJS();
  if (
    selectedQuestion &&
    selectedQuestion.get('meta').get('title') &&
    selectedQuestion.get('meta').get('description')
  ) {
    meta = selectedQuestion.get('meta').toJS();
  }

  const jsonLD = {
    '@type': 'FAQPage',
    mainEntity: jsonLDQuestions,
  };

  return (
    <section className="help-center__main-column help-center__main-column--last">
      <HeaderMeta page={'help-center'} meta={meta} />
      <JsonLD json={jsonLD} />
      <div className="help-center__tabs">
        {tabs.map((tab, index) => {
          return (
            <div
              key={index}
              className={classNames('help-center__tab', {
                'help-center__tab--active': currentTab === tab.value,
              })}
            >
              <button type="button" className="help-center__tab-button" onClick={() => onTabChange(tab.value)}>
                {tab.label}
              </button>
            </div>
          );
        })}
      </div>
      <div className="container container--padding">
        <FieldWrapper
          id={'identity'}
          className={'field-wrapper--select help-center__select'}
          label={<Trans>help-center.identity.title</Trans>}
          hasValue={true}
        >
          <SelectField
            name={'identity'}
            value={currentTab}
            options={tabs}
            onChange={(e) => onTabChange(e.target.value)}
          />
        </FieldWrapper>
        <div className="help-center__tab-content">
          <h2 className="help-center__category-title">{category.get('name')}</h2>
          {questions.map((question, index) => (
            <Advice
              key={index}
              id={question.get('slug')}
              title={question.get('question')}
              answer={question.get('answer').toJS()}
              isOpen={selectedQuestion && selectedQuestion.get('slug') === question.get('slug')}
              toggleQuestion={() => onQuestionChange(question)}
              linkProps={{
                to: paths.HELP_CENTER,
                params: { slug: category.get('slug'), question: question.get('slug') },
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

FAQBlock.propTypes = {
  category: PropTypes.instanceOf(Map).isRequired,
  locale: PropTypes.string.isRequired,
  selectedQuestion: PropTypes.object,
  selectedTab: PropTypes.string,
};

export default FAQBlock;
