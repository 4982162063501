import React from 'react';

import SkeletonItem from '../../components/SkeletonItem';

const HelpCenterSidebarSkeleton = () => (
  <div className="help-center__main-column help-center__main-column--second">
    <div className="container container--padding">
      <SkeletonItem style={{ height: '70px', width: '100%' }} className={'field-wrapper--select'} />
      <ul className="help-center__categories">
        {[...Array(3)].map((tab, key) => (
          <li key={key} className={'help-center__category'}>
            <SkeletonItem
              className={'skeleton-item--round'}
              style={{ height: '18px', width: '18px', marginRight: '10px' }}
            />
            <SkeletonItem style={{ height: '18px', width: '40%', marginTop: '2px', marginBottom: '2px' }} />
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default HelpCenterSidebarSkeleton;
